import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

Sentry.init({
  dsn: 'https://78f618440fa94606af406f18953d0bea@o4504487674314752.ingest.sentry.io/4504488179597312',
  environment: import.meta.env.VITE_BUILD_ENV,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Integrations.Breadcrumbs({ console: false }),
  ],
  tracesSampleRate: 0.2,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export { Sentry, SentryRoutes };
