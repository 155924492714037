/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "micashared.common.v1";

export interface BankAccountDetail {
  /** The organizations domestic ACH routing number (9 characters) */
  domesticAchRoutingNumber: string;
  /** The organizations international ACH routing number (9 characters) */
  internationalAchRoutingNumber: string;
  /** The organizations wire routing number (9 characters) */
  wireRoutingNumber: string;
  /** The organizations swift routing number (9 characters) */
  swiftRoutingNumber: string;
  /** The organizations bank account number (14 digits) */
  bankAccountNumber: string;
}

function createBaseBankAccountDetail(): BankAccountDetail {
  return {
    domesticAchRoutingNumber: "",
    internationalAchRoutingNumber: "",
    wireRoutingNumber: "",
    swiftRoutingNumber: "",
    bankAccountNumber: "",
  };
}

export const BankAccountDetail = {
  encode(message: BankAccountDetail, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.domesticAchRoutingNumber !== "") {
      writer.uint32(10).string(message.domesticAchRoutingNumber);
    }
    if (message.internationalAchRoutingNumber !== "") {
      writer.uint32(18).string(message.internationalAchRoutingNumber);
    }
    if (message.wireRoutingNumber !== "") {
      writer.uint32(26).string(message.wireRoutingNumber);
    }
    if (message.swiftRoutingNumber !== "") {
      writer.uint32(34).string(message.swiftRoutingNumber);
    }
    if (message.bankAccountNumber !== "") {
      writer.uint32(42).string(message.bankAccountNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BankAccountDetail {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBankAccountDetail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.domesticAchRoutingNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.internationalAchRoutingNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.wireRoutingNumber = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.swiftRoutingNumber = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.bankAccountNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<BankAccountDetail>, I>>(base?: I): BankAccountDetail {
    return BankAccountDetail.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<BankAccountDetail>, I>>(object: I): BankAccountDetail {
    const message = createBaseBankAccountDetail();
    message.domesticAchRoutingNumber = object.domesticAchRoutingNumber ?? "";
    message.internationalAchRoutingNumber = object.internationalAchRoutingNumber ?? "";
    message.wireRoutingNumber = object.wireRoutingNumber ?? "";
    message.swiftRoutingNumber = object.swiftRoutingNumber ?? "";
    message.bankAccountNumber = object.bankAccountNumber ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };
