/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../../google/protobuf/timestamp";

export const protobufPackage = "micashared.common.ping.v1";

export interface PingRequest {
}

export interface PingResponse {
  status: PingResponse_Status;
  serverTime: Date | undefined;
  serverStartTime: Date | undefined;
  buildVersion: string;
  buildSha: string;
  buildTime: string;
  serviceType: string;
}

export enum PingResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
}

function createBasePingRequest(): PingRequest {
  return {};
}

export const PingRequest = {
  encode(_: PingRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PingRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePingRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<PingRequest>, I>>(base?: I): PingRequest {
    return PingRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PingRequest>, I>>(_: I): PingRequest {
    const message = createBasePingRequest();
    return message;
  },
};

function createBasePingResponse(): PingResponse {
  return {
    status: 0,
    serverTime: undefined,
    serverStartTime: undefined,
    buildVersion: "",
    buildSha: "",
    buildTime: "",
    serviceType: "",
  };
}

export const PingResponse = {
  encode(message: PingResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.serverTime !== undefined) {
      Timestamp.encode(toTimestamp(message.serverTime), writer.uint32(18).fork()).ldelim();
    }
    if (message.serverStartTime !== undefined) {
      Timestamp.encode(toTimestamp(message.serverStartTime), writer.uint32(58).fork()).ldelim();
    }
    if (message.buildVersion !== "") {
      writer.uint32(26).string(message.buildVersion);
    }
    if (message.buildSha !== "") {
      writer.uint32(34).string(message.buildSha);
    }
    if (message.buildTime !== "") {
      writer.uint32(42).string(message.buildTime);
    }
    if (message.serviceType !== "") {
      writer.uint32(50).string(message.serviceType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PingResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePingResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serverTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.serverStartTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.buildVersion = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.buildSha = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.buildTime = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.serviceType = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<PingResponse>, I>>(base?: I): PingResponse {
    return PingResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PingResponse>, I>>(object: I): PingResponse {
    const message = createBasePingResponse();
    message.status = object.status ?? 0;
    message.serverTime = object.serverTime ?? undefined;
    message.serverStartTime = object.serverStartTime ?? undefined;
    message.buildVersion = object.buildVersion ?? "";
    message.buildSha = object.buildSha ?? "";
    message.buildTime = object.buildTime ?? "";
    message.serviceType = object.serviceType ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}
