/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../../google/protobuf/timestamp";
import { Address } from "../../../../micashared/common/v1/address";
import { BankAccountDetail } from "../../../../micashared/common/v1/bank_account_detail";
import { Error } from "../../../../micashared/common/v1/error";

export const protobufPackage = "mica.discount.discountprovider.v1";

export interface DiscountProvider {
  discountProviderKey: string;
  version: number;
  created: Date | undefined;
  updated: Date | undefined;
  name: string;
  address: Address | undefined;
  callbackAddress: string;
  email: string;
  phone: string;
  /** Bank account of the Discount Provider to send revenue to */
  bankAccountDetail: BankAccountDetail | undefined;
}

export interface GetDiscountProviderRequest {
}

export interface GetDiscountProviderResponse {
  status: GetDiscountProviderResponse_Status;
  error: Error | undefined;
  discountProvider: DiscountProvider | undefined;
}

export enum GetDiscountProviderResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface UpdateDiscountProviderRequest {
  version: number;
  name: string;
  address: Address | undefined;
  callbackAddress: string;
  email: string;
  phone: string;
  bankAccountDetail: BankAccountDetail | undefined;
}

export interface UpdateDiscountProviderResponse {
  status: UpdateDiscountProviderResponse_Status;
  error: Error | undefined;
  version: number;
}

export enum UpdateDiscountProviderResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

function createBaseDiscountProvider(): DiscountProvider {
  return {
    discountProviderKey: "",
    version: 0,
    created: undefined,
    updated: undefined,
    name: "",
    address: undefined,
    callbackAddress: "",
    email: "",
    phone: "",
    bankAccountDetail: undefined,
  };
}

export const DiscountProvider = {
  encode(message: DiscountProvider, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountProviderKey !== "") {
      writer.uint32(10).string(message.discountProviderKey);
    }
    if (message.version !== 0) {
      writer.uint32(16).int64(message.version);
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(26).fork()).ldelim();
    }
    if (message.updated !== undefined) {
      Timestamp.encode(toTimestamp(message.updated), writer.uint32(34).fork()).ldelim();
    }
    if (message.name !== "") {
      writer.uint32(42).string(message.name);
    }
    if (message.address !== undefined) {
      Address.encode(message.address, writer.uint32(58).fork()).ldelim();
    }
    if (message.callbackAddress !== "") {
      writer.uint32(66).string(message.callbackAddress);
    }
    if (message.email !== "") {
      writer.uint32(74).string(message.email);
    }
    if (message.phone !== "") {
      writer.uint32(82).string(message.phone);
    }
    if (message.bankAccountDetail !== undefined) {
      BankAccountDetail.encode(message.bankAccountDetail, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DiscountProvider {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiscountProvider();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.discountProviderKey = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.name = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.address = Address.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.callbackAddress = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.email = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.phone = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.bankAccountDetail = BankAccountDetail.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DiscountProvider>, I>>(base?: I): DiscountProvider {
    return DiscountProvider.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DiscountProvider>, I>>(object: I): DiscountProvider {
    const message = createBaseDiscountProvider();
    message.discountProviderKey = object.discountProviderKey ?? "";
    message.version = object.version ?? 0;
    message.created = object.created ?? undefined;
    message.updated = object.updated ?? undefined;
    message.name = object.name ?? "";
    message.address = (object.address !== undefined && object.address !== null)
      ? Address.fromPartial(object.address)
      : undefined;
    message.callbackAddress = object.callbackAddress ?? "";
    message.email = object.email ?? "";
    message.phone = object.phone ?? "";
    message.bankAccountDetail = (object.bankAccountDetail !== undefined && object.bankAccountDetail !== null)
      ? BankAccountDetail.fromPartial(object.bankAccountDetail)
      : undefined;
    return message;
  },
};

function createBaseGetDiscountProviderRequest(): GetDiscountProviderRequest {
  return {};
}

export const GetDiscountProviderRequest = {
  encode(_: GetDiscountProviderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDiscountProviderRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDiscountProviderRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetDiscountProviderRequest>, I>>(base?: I): GetDiscountProviderRequest {
    return GetDiscountProviderRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetDiscountProviderRequest>, I>>(_: I): GetDiscountProviderRequest {
    const message = createBaseGetDiscountProviderRequest();
    return message;
  },
};

function createBaseGetDiscountProviderResponse(): GetDiscountProviderResponse {
  return { status: 0, error: undefined, discountProvider: undefined };
}

export const GetDiscountProviderResponse = {
  encode(message: GetDiscountProviderResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.discountProvider !== undefined) {
      DiscountProvider.encode(message.discountProvider, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDiscountProviderResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDiscountProviderResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.discountProvider = DiscountProvider.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetDiscountProviderResponse>, I>>(base?: I): GetDiscountProviderResponse {
    return GetDiscountProviderResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetDiscountProviderResponse>, I>>(object: I): GetDiscountProviderResponse {
    const message = createBaseGetDiscountProviderResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.discountProvider = (object.discountProvider !== undefined && object.discountProvider !== null)
      ? DiscountProvider.fromPartial(object.discountProvider)
      : undefined;
    return message;
  },
};

function createBaseUpdateDiscountProviderRequest(): UpdateDiscountProviderRequest {
  return {
    version: 0,
    name: "",
    address: undefined,
    callbackAddress: "",
    email: "",
    phone: "",
    bankAccountDetail: undefined,
  };
}

export const UpdateDiscountProviderRequest = {
  encode(message: UpdateDiscountProviderRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.version !== 0) {
      writer.uint32(8).int64(message.version);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.address !== undefined) {
      Address.encode(message.address, writer.uint32(26).fork()).ldelim();
    }
    if (message.callbackAddress !== "") {
      writer.uint32(34).string(message.callbackAddress);
    }
    if (message.email !== "") {
      writer.uint32(42).string(message.email);
    }
    if (message.phone !== "") {
      writer.uint32(50).string(message.phone);
    }
    if (message.bankAccountDetail !== undefined) {
      BankAccountDetail.encode(message.bankAccountDetail, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDiscountProviderRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDiscountProviderRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.address = Address.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.callbackAddress = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.email = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.phone = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.bankAccountDetail = BankAccountDetail.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateDiscountProviderRequest>, I>>(base?: I): UpdateDiscountProviderRequest {
    return UpdateDiscountProviderRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateDiscountProviderRequest>, I>>(
    object: I,
  ): UpdateDiscountProviderRequest {
    const message = createBaseUpdateDiscountProviderRequest();
    message.version = object.version ?? 0;
    message.name = object.name ?? "";
    message.address = (object.address !== undefined && object.address !== null)
      ? Address.fromPartial(object.address)
      : undefined;
    message.callbackAddress = object.callbackAddress ?? "";
    message.email = object.email ?? "";
    message.phone = object.phone ?? "";
    message.bankAccountDetail = (object.bankAccountDetail !== undefined && object.bankAccountDetail !== null)
      ? BankAccountDetail.fromPartial(object.bankAccountDetail)
      : undefined;
    return message;
  },
};

function createBaseUpdateDiscountProviderResponse(): UpdateDiscountProviderResponse {
  return { status: 0, error: undefined, version: 0 };
}

export const UpdateDiscountProviderResponse = {
  encode(message: UpdateDiscountProviderResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.version !== 0) {
      writer.uint32(24).int64(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDiscountProviderResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDiscountProviderResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateDiscountProviderResponse>, I>>(base?: I): UpdateDiscountProviderResponse {
    return UpdateDiscountProviderResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateDiscountProviderResponse>, I>>(
    object: I,
  ): UpdateDiscountProviderResponse {
    const message = createBaseUpdateDiscountProviderResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.version = object.version ?? 0;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
